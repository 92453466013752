import { intersection, keyBy, memoize } from 'lodash';
import { KdsTicket } from '../types/ticket';
import { KdsLine, KdsLinesMap } from '../types/kds-line';

const containsTags =
    (activeTags: string[]) =>
    ({ tags }: KdsTicket | KdsLine): boolean => {
        if (!tags) {
            return false;
        }
        return (
            intersection(
                activeTags.map((tag) => tag.toLocaleLowerCase()),
                tags.map((tag) => tag.toLocaleLowerCase()),
            ).length > 0
        );
    };

const filterLineByTags = (kdsLinesMap: KdsLinesMap, tags: string[]): KdsLinesMap =>
    keyBy(
        Object.values(kdsLinesMap)
            .filter(containsTags(tags))
            .map((line) => ({
                ...line,
                kdsLines: filterLineByTags(line.kdsLines, tags),
            })),
        'uuid',
    );

export const getTicketsFilteredByTag = (tickets: KdsTicket[], tags: string[]): KdsTicket[] => {
    if (tags.length === 0) {
        return tickets;
    }

    return tickets.filter(containsTags(tags)).map(
        memoize((ticket) => ({
            ...ticket,
            kdsLines: filterLineByTags(ticket.kdsLines, tags),
        })),
    );
};
